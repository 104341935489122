
.TrainData {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    .main {
        flex: 1;
        overflow: hidden;
        background-color: #f8f9fb;

        .content {
            border-radius: 4px;
            box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
            background-color: #fff;
        }

        .container {
            box-sizing: border-box;
            display: flex;
            width: 100%;
            padding: 2% 3%;
            overflow: hidden;

            .left-container {
                flex: 1.2;

                .base-info {
                    display: grid;
                    margin-right: 30px;
                    column-gap: 10px;
                    grid-template-columns: 1fr 1fr 1fr;

                    .item {
                        @extend .content;
                        padding: 0px 10%;
                        height: 180px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;

                        .header {
                            display: flex;
                            align-items: center;
                            font-size: 16px;

                            .iconfont {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 50px;
                                height: 50px;
                                margin-right: 10px;
                                font-size: 26px;
                                color: #fff;
                                background-color: #FF9500;
                                border-radius: 10px;
                            }

                            .label {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;
                                height: 100%;

                                .hint {
                                    color: #767676;
                                    font-size: 14px;
                                }
                            }
                        }

                        .data {
                            font-size: 34px;
                        }
                    }
                }

                .chart-list {
                    display: flex;
                    box-sizing: border-box;
                    height: 430px;
                    margin-top: 30px;
                    margin-right: 30px;

                    .chart-item {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        &:first-child {
                            margin-right: 30px;
                        }

                        .title {
                            margin-bottom: 10px;
                            font-size: 16px;
                            font-weight: bold;
                        }

                        .chart {
                            flex: 1;
                        }
                    }
                }
            }


            .sales-list {
                flex: 0.5;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                padding: 2%;
                overflow: hidden;

                .title {
                    margin-bottom: 15px;
                }

                .table-data {
                    flex: 1;
                    overflow: hidden;

                    .flex-content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .rank {
                        width: 58px;
                    }

                    .goods {
                        flex: 1;
                    }

                    .sales {
                        width: 98px;
                    }

                    .rank,
                    .goods,
                    .sales {
                        @extend .flex-content;
                    }

                    .th {
                        display: flex;
                        height: 40px;

                        div:not(:first-child) {
                            margin-left: 4px;
                        }

                        div {
                            background-color: #F8F9FB;
                        }
                    }

                    .tr {
                        width: 100%;
                        overflow: hidden;

                        .item {
                            display: flex;
                            height: 50px;
                            width: 100%;
                            margin-top: 4px;
                            overflow: hidden;

                            &:first-child {
                                background-color: #fff8e5;
                            }

                            &:nth-child(2) {
                                background-color: #f5faff;
                            }

                            &:nth-child(3) {
                                background-color: #fffcfa;
                            }

                            .goods {
                                box-sizing: border-box;
                                justify-content: flex-start;
                                padding: 0 10px;
                                overflow: hidden;

                                div {
                                    width: 100%;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                            }

                            &:hover {
                                cursor: pointer;
                                background-color: #f8f8f8;
                            }
                        }
                    }
                }
            }
        }
    }
}

::v-deep .el-scrollbar__wrap {
    overflow-x: hidden !important;
}