
.flex {
    display: flex;
}
.w-full {
    width: 100%;
}
.flex-1 {
    flex: 1;
}
.items-center {
    align-items: center;
}
.justify-end {
    justify-content: flex-end;
}
.mr-1 {
    margin-right: 4px;
}
.top-navbar {
    box-sizing: border-box;
    height: 50px;
    padding: 0 20px;
    box-shadow: 0 3px 6px 1px rgba(0,0,0,.1);
    background-color:#021e39;
    .countdown {
        width: fit-content;
        color: #ccc;
        .iconfont {
            margin-right: 7px;
            font-size: 18px;
        }
    }
    .user-info {
        color: #ccc;
        font-size: 14px;
        .avatar {
            box-sizing: border-box;
            width: 32px;
            height: 32px;
            margin-right: 8px;
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid #E6E7EB;
            vertical-align: bottom;
        }
    }
    .right-containers {
        .back-button {
            margin-right: 8px;
            .iconfont {
                margin-right: 5px;
            }
        }
    }
}
